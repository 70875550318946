.Mui-selected {
    border-radius: 0.25rem 0.25rem 0rem 0rem !important;
    border-top: 1px solid #9ca3af !important;
    border-right: 1px solid #9ca3af !important;
    border-left: 1px solid #9ca3af !important;
    background: #f9fafb !important;
    border-bottom: none !important;
    color: #4b5563 !important;
    font-weight: 700 !important;
  }
  
  .MuiTabs-indicator {
    background: white !important;
  }
  
  .MuiBreadcrumbs-li {
    font-size: 0.83rem !important;
}