@media screen and (max-width: 1452px) {
    .lastReresh {
        display: none !important;
    }
    .css-plt32h {
        margin: 20px !important;
    }
}

.sidebarwidth{
    position: relative;
}

.outletwidth{
    margin-left: 6.1vw;
    width: 93vw;
    position: relative;
}

.sidebar{
    height: 91vh;
    width: 6vw;
}

.filtermenucontainer{
    width: fit-content;
    position: relative;
    filter: drop-shadow(0px 2px 8px rgba(0,0,0,0.32));
}

.filter{
    background-color: white;
    position: relative;
    margin-top: 1rem;
    padding: 10px 10px;
}

.filter::before{
    content: ' ';
    display: block;
    position: absolute;
    top: 0;
    left: 14px;
    width: 10px;
    height: 10px;
    background-color: white;
    transform: translateY(-50%) rotate(45deg);
    z-index: 100;
}

.inputGrp{
    display: flex;
}

.form-input{
    margin-right: 10px;
}

/* login page */
.mainBody{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.mainBody div:nth-child(1) {
    display: flex;
    flex-direction: column;
}

.title {
    text-align: center;
    font-size: 32px;
    font-weight: bolder !important;
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
}

form{
    width: 600px;
    display: flex;
    flex-direction: column;
}



.mainBody .form-group {
    margin-bottom: 1rem;
}


.MuiTextField-root{
    width: 100%;
}