.loginBtn {
    width: 70%;
    align-self: center;
    font-size: 1rem;
    height: 48px;
    min-height: 48px;
    padding: 13px 20px;
    background: #1976d2;
    border-color: #2b0eaf;
    color: white;
    border-radius: 18px;
    border: none;
    font-family: "Market Sans", Arial, sans-serif;
    font-weight: 700;
    font-style: normal;
    font-stretch: normal;
}

.nostyle {
    border: none;
    background: none;
    outline: none;
}

.closeBtn {
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgb(25, 118, 210);
    color: white;
    border: none;
    outline: none;
}

.filterclosebtn{
    width: 1rem;
    height: 1rem;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgb(25, 118, 210);
    color: white;
    border: none;
    outline: none;
    position: absolute;
    right: 10px;
}

.scanbarcodebtn {
    font-size: 14px;
    font-family: Roboto;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0px;
    display: flex;
    justify-content: space-around;
}

.filterapplybtn{
    height: 2rem;
    margin-top: 1.4rem !important;
}

.searchButton{
    font-size: 12px;
    height: 3rem;
    font-family: Roboto !important;
    font-weight: 400 !important;
    line-height: 24px !important;
    letter-spacing: 0px;
}