.blueInput {
    border-radius: 0.25rem;
    border: 1px solid #1976d2;
    background: rgba(25, 118, 210, 0.2);
    height: 2.5rem;
    width: 100%;
}

.blueInput:focus{
    outline: none;
}

.salesinput{
    display: flex;
    align-items: center;
    margin-top: 1rem;
}

/* uses in the search modal on sales tab */
.searchInput{
    height: 3rem;
    width: 30rem;
    border: 1px solid #9CA3AF;
    padding: 12px;
    border-radius: 4px;
    outline: none;
}

.searchInpu2{
    height: 3rem;
    width: 30rem;
    border: 1px solid #9CA3AF;
    padding: 12px;
    border-radius: 4px;
    outline: none;
}

.searchInpu3{
    height: 3rem;
    width: 100%;
    border: 1px solid #9CA3AF;
    padding: 12px;
    border-radius: 4px;
    outline: none;
}

/* used in payment of sales */
.cashInput{
    margin-left: 10px;
    width: 6rem;
    padding: 0.5rem;
}

 /* used in inventor */
.filterInput{
    width: 5rem;
    border-radius: 10px;
    outline: none;
    border: 1px solid black;
}

.filterInput-lg{
    width: 8rem;
    border-radius: 10px;
    outline: none;
    border: 1px solid black;
}