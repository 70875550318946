/* 
    @headings 
*/
.headerStyle1 {
    color: #000;
    text-align: center;
    font-family: Roboto;
    font-size: 2rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1.75rem; /* 87.5% */
    letter-spacing: 0.00938rem;
}

/* product details */
.subHead {
    font-family: Roboto;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.17000000178813934px;
    text-align: left;
}

.tabHeader {
    font-family: Roboto;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1.5rem; /* 120% */
}

/* @paragraphs */
.paragraphStyle1 {
    color: #000;

    /* 20' Regular */
    font-family: Roboto;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5rem; /* 120% */
}

/* used in scannermodal */
.paragraphStyle2 {
    color: #1f2937;
    /* 14' Regular */
    font-family: Roboto;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 143%; /* 1.25125rem */
    letter-spacing: 0.01063rem;
}

/* 
    @labels
*/
.labelstyle1 {
    color: #000;

    /* 20' Bold */
    font-family: Roboto;
    font-size: 1rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1.5rem; /* 120% */
}

/* used in detailed view of sale */
.labelStyle2 {
    font-family: Roboto;
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
}

.lablestyle3{
    font-family: Roboto;
    font-size: 12px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
}

/* 
    @Sidemenu item label
*/

.sidemenulabel {
    text-align: center;
    font-feature-settings: "clig" off, "liga" off;
    font-family: Roboto;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 600;
    line-height: 1.25rem; /* 142.857% */
    width: 82%;
}
